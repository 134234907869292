<template>
  <div class="max-w-onboarding">
    <NewMessage
      v-for="(message, index) in newMessages"
      :newMessage="message"
      :key="`${message.id}${Math.random()}new-message`"
      :index="index"
      :cancel="cancelNewMessage"
      :save="saveNewMessage"
    />
    <div class="flex justify-between mt-3">
      <p
        v-if="!hasNewMessages"
        class="text-grey-dark-1 text-mdl font-normal ml-3"
      >
        No email or new messages created yet.
      </p>
      <Button
        text="New Message"
        type="secondary"
        size="medium"
        :iconLeft="add"
        @click.native="addNewMessage"
        class="ml-auto"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import add from "@/assets/img/icons/add-blue.svg";

import NewMessage from "./NewMessage.vue";
import Button from "../../../../components/Button/Button.vue";

export default {
  name: "OtherMessagesContent",
  components: { Button, NewMessage },
  data: () => ({
    add,
    newMessages: [],
    newMessage: {
      from: "",
      subject: "",
      message: "",
      messageAudience: 0,
      messageFrequency: 1,
      surveyMessageType: 2,
      messageName: "",
      id: null,
    },
  }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
      communication: (state) => state.products.communication,
    }),
    hasNewMessages() {
      return !!this.newMessages?.length;
    },
  },
  methods: {
    ...mapActions({
      updateSurveyCommunication: "products/updateSurveyCommunication",
      getSurveyCommunication: "products/getSurveyCommunication",
    }),
    addNewMessage() {
      this.newMessages?.push({ ...this.newMessage });
    },
    cancelNewMessage(index) {
      this.newMessages?.splice(index, 1);
    },
    async saveNewMessage(index) {
      const {
        message,
        subject,
        messageName,
        messageAudience: audience,
        messageFrequency,
        nrDays,
        id: messageId,
      } = this.newMessages[index];
      const payload = {
        surveyId: this.productDetails.id,
        messageType: 2,
        audience,
        message,
        subject,
        messageName,
        messageFrequency,
        messageId,
        nrDays,
      };
      await this.updateSurveyCommunication({
        id: this.productDetails.id,
        payload,
      }).then(() => {
        this.getSurveyCommunication(this.productDetails.id);
      });
    },
  },
  mounted() {
    const otherMessages = this.communication?.filter(
      (item) => item?.surveyMessageType === 2
    );
    this.newMessages = [...otherMessages];
    this.newMessage.from = this.communication?.find(
      (item) => item.surveyMessageType === 1
    )?.from;
  },
};
</script>
