<template>
  <div class="bg-grey-border rounded p-3">
    <div class="max-w-3/4">
      <p class="text-mdh text-grey-dark-1 font-bold mb-2">{{ type }} Email</p>
      <div class="flex mb-5">
        <div class="w-1/2">
          <p class="text-grey-light text-sm-2 mb-1">Message Name</p>
          <p class="text-grey-dark-1 text-mdh">
            {{ messageName || `${type} Email` }}
          </p>
        </div>
        <div class="w-1/2">
          <p class="text-grey-light text-sm-2 mb-1">Audience</p>
          <p class="text-grey-dark-1 text-mdh">{{ audience }}</p>
        </div>
      </div>
      <div class="flex mb-5">
        <div class="w-1/2">
          <p class="text-grey-light text-sm-2 mb-1">Subject</p>
          <p class="text-grey-dark-1 text-mdh">{{ subject }}</p>
        </div>
        <div class="w-1/2">
          <p class="text-grey-light text-sm-2 mb-1">Rules</p>
          <p class="text-grey-dark-1 text-mdh">{{ messageFrequency }}</p>
        </div>
      </div>
    </div>
    <RichText
      label="Message"
      :content="html"
      :editable="false"
      :hasGreyText="false"
    />
  </div>
</template>
<script>
import RichText from "@/components/RichText/TipTap.vue";

export default {
  name: "ReadOnlyEmailCommunication",
  props: {
    type: { default: "Invitation", type: "Invitation" | "Reminder" },
    currentCommunication: { default: () => {}, type: Object },
  },
  components: { RichText },
  computed: {
    messageName() {
      return this.currentCommunication?.messageName;
    },
    audience() {
      const audience = this.currentCommunication?.messageAudience;
      switch (audience) {
        case 0:
          return "All Participants";
        case 1:
          return "Participants with Assessments in Progress";
        case 2:
          return "Only Managers";
        case 3:
          return "Only Employees";
        default:
          return "";
      }
    },
    subject() {
      return this.currentCommunication?.subject;
    },
    messageFrequency() {
      return this.currentCommunication?.messageFrequency === 0
        ? "No Repeat"
        : ` Repeat every ${this.currentCommunication?.nrDays && this.currentCommunication?.nrDays > 1 ? `${this.currentCommunication?.nrDays} days` : 'day'} until close date`
    },
    html() {
      return this.currentCommunication?.message;
    },
  },
};
</script>
