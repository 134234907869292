<template>
  <div class="mb-3">
    <p class="font-medium text-sm-2 text-grey-light mb-1.5">{{type}} Image</p>
    <div class="h-38 w-full border rounded border-grey-light-4 flex gap-3">
      <Button
        text="Choose File"
        size="medium"
        type="secondary"
        @click.native="uploadImage"
        :iconLeft="add"
        class="px-3 min-w-160"
      />
      <input
        type="file"
        accept="image/png, image/gif, image/jpeg"
        ref="imageFile"
        @change="handleImageUpload"
        class="hidden"
      />
      <div v-if="image" class="flex items-center w-full mr-1.5">
        <p
          class="text-mdh font-bold text-blue-dark-1 cursor-pointer"
          @click="downloadImage"
        >
          {{ image.fileName }}
          <span class="text-grey-light ml-0.5">{{
            `(${image.fileSize})`
          }}</span>
        </p>
        <div
          class="cursor-pointer h-38 w-38 flex items-center justify-center ml-auto"
          @click="deleteImage"
        >
          <img :src="close" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import add from "@/assets/img/icons/add-blue.svg";
import { mapActions, mapState } from "vuex";
import { saveAs } from "file-saver";

import close from "../../../../assets/img/icons/close-grey.svg";
import Button from "../../../../components/Button/Button.vue";
import {
  downloadSurveyImage,
  deleteSurveyImage,
} from "../../../../services/api/products";
import {getImageDataByType} from './imageUtils';

export default {
  name: "SurveyImage",
  components: { Button },
  data: () => ({ add, close }),
  props: {
    type: { default: "Intro", type: "Intro" | "Outro" | "Closed" },
  },
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
      products: (state) => state.products,
    }),
    imageDetails() {
      return getImageDataByType(this.type)
    },
    image()  {
      return this.products?.[this.imageDetails.name];
    },
  },
  methods: {
    ...mapActions({
      uploadSurveyImage: "products/uploadSurveyImage",
      getSurveyImage: "products/getSurveyImage",
    }),
    async downloadImage() {
      const rsp = await downloadSurveyImage(this.image.id);
      saveAs(rsp.data, this.image.fileName);
    },
    deleteImage() {
      deleteSurveyImage(this.productDetails.id, this.imageDetails.type).then(() =>
        this.getSurveyImage({id: this.productDetails.id, type: this.type})
      );
    },
    uploadImage() {
      this.$refs.imageFile.click();
    },
    handleImageUpload() {
      const image = this.$refs.imageFile.files[0];
      const formData = new FormData();
      formData.append("file", image);
      formData.append("type", this.imageDetails.type)
      this.uploadSurveyImage({
        id: this.productDetails.id,
        file: formData,
        type: this.imageDetails.type,
      }).then(() => this.getSurveyImage({id: this.productDetails.id, type: this.type}));
    },
  },
};
</script>
