<template>
  <div class="mx-4">
    <Accordion>
      <AccordionItem class="w-full mb-4">
        <template slot="accordion-trigger">
          <CommunicationItem :title="title" />
        </template>
        <template slot="accordion-content">
          <EmailCommunicationContent :type="type" />
        </template>
      </AccordionItem>
    </Accordion>
  </div>
</template>
<script>
import AccordionItem from "../../../../components/Accordion/AccordionItem.vue";
import Accordion from "../../../../components/Accordion/Accordion.vue";
import CommunicationItem from "../SurveyCommunication/CommunicationItem.vue";
import EmailCommunicationContent from "./EmailCommunicationContent.vue";

export default {
  name: "EmailCommunication",
  props: { type: { default: "Invitation", type: "Invitation" | "Reminder" } },
  components: {
    Accordion,
    AccordionItem,
    CommunicationItem,
    EmailCommunicationContent,
  },
  computed: {
    title() {
      return this.type === "Invitation" ? "Invitation Email" : "Reminder Email";
    },
  },
};
</script>
