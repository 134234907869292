<template>
  <div class="max-w-onboarding">
    <SurveyImage  :type="type" />
    <p class="mb-2 text-grey-dark-1 font-normal text-mdh">
      {{descriptions[type]}}
    </p>
    <RTF
      label="Message"
      :key="`${type}${rtfKey}`"
      :content="html"
      :haveMinHeight="true"
      :updateHtml="(value) => (html = value)"
    />
    <Button
      text="Update"
      type="primary"
      size="medium"
      class="ml-auto px-5"
      :disabled="disableSave"
      @click.native="save"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import Button from "../../../../components/Button/Button.vue";
import SurveyImage from "./SurveyImage.vue";

export default {
  name: "SurveyCommunicationContent",
  props: {
    type: { default: "Intro", type: "Intro" | "Outro" | "Closed" },
  },
  components: { Button, SurveyImage },
  data: () => ({
    rtfKey: 1,
    descriptions: {
      "Intro": "This information will be shown to participants in the very first screen after they start an assessment.",
      "Outro": "This information will be shown to participants after they answer the last question of the assessment.",
      "Closed": "This information will be shown to participants if the access the assessment after it was closed or it expired."
    }
  }),
  watch: {
    communication() {
      this.rtfKey += 1;
    },
  },
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
      communication: (state) => state.products.communication,
    }),
    disableSave() {
      return this.html === "<p></p>";
    },
    messageType() {
      const types = {
        "Intro":  10,
        "Outro": 11,
        "Closed": 12,
      }
      return types[this.type];
    },
    currentCommunication() {
      return this.communication?.find(
        (item) => item?.surveyMessageType === this.messageType
      );
    },
    html: {
      get() {
        return this.currentCommunication?.message;
      },
      set(value) {
        this.setCommunicationMessage({ type: this.messageType, value });
      },
    },
  },
  methods: {
    ...mapActions({
      setCommunicationMessage: "products/setCommunicationMessage",
      updateSurveyCommunication: "products/updateSurveyCommunication",
      getSurveyCommunication: "products/getSurveyCommunication",
    }),
    async save() {
      const { message, id } = this.currentCommunication;
      const payload = {
        surveyId: this.productDetails.id,
        message: message,
        messageType: this.messageType,
        messageId: id || null,
      };
      await this.updateSurveyCommunication({
        id: this.productDetails.id,
        payload,
      }).then(() => this.getSurveyCommunication(this.productDetails.id));
    },
  },
};
</script>
