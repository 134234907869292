<template>
    <div class="bg-grey-border rounded p-3">
        <p class="text-grey-light text-sm-2 mb-1">{{type}} Image</p>
        <p v-if="image" class="text-mdh font-bold text-blue-dark-1 cursor-pointer inline-block" @click="downloadImage">
            {{ fileName }}
            <span v-if="fileSize" class="text-grey-light ml-0.5">{{
                `(${fileSize})`
            }}</span>
        </p>
        <p v-else class="text-grey-dark-1 text-md">No Intro Image Added</p>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { saveAs } from "file-saver";
import {getImageDataByType} from '../SurveyCommunication/imageUtils';
import { downloadSurveyImage } from "../../../../services/api/products";

export default {
    name: "ReadOnlySurveyCommunicationImage",
    props: {
        type: { default: "Intro", type: "Intro" | "Outro" | "Closed" },
    },
    computed: {
        ...mapState({
            products: (state) => state.products,
        }),
        imageDetails() {
            return getImageDataByType(this.type)
        },
        image() {
            return this.products?.[this.imageDetails.name];
        },
        fileName() {
            return this.image?.fileName || "";
        },
        fileSize() {
            return this.image?.fileSize || "";
        },
    },
    methods: {
        async downloadImage() {
            const rsp = await downloadSurveyImage(this.image.id);
            saveAs(rsp.data, this.image.fileName);
        },
    },
};
</script>
  