<template>
  <div class="mx-4">
    <Accordion>
      <AccordionItem class="w-full mb-4">
        <template slot="accordion-trigger">
          <CommunicationItem :title="'Other messages'" />
        </template>
        <template slot="accordion-content">
          <OtherMessagesContent />
        </template>
      </AccordionItem>
    </Accordion>
  </div>
</template>
<script>
import AccordionItem from "../../../../components/Accordion/AccordionItem.vue";
import Accordion from "../../../../components/Accordion/Accordion.vue";
import CommunicationItem from "../SurveyCommunication/CommunicationItem.vue";
import OtherMessagesContent from "./OtherMessagesContent.vue";

export default {
  name: "OtherMessages",
  components: {
    Accordion,
    AccordionItem,
    CommunicationItem,
    OtherMessagesContent,
  },
};
</script>
