<template>
  <div class="bg-grey-border rounded p-3">
    <RichText
      class="max-w-2/3"
      :label="`${type} Message`"
      :content="html"
      :editable="false"
      :hasGreyText="false"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import RichText from "@/components/RichText/TipTap.vue";

export default {
  name: "ReadOnlySurveyCommunication",
  components: { RichText },
  props: { type: { default: "Intro", type: "Intro" | "Outro" | "Closed" } },
  computed: {
    ...mapState({
      communication: (state) => state.products.communication,
    }),
    messageType() {
      const messageTypes = {
        "Intro": 10,
        "Outro": 11,
        "Closed": 12
      }
      return messageTypes[this.type];
    },
    currentCommunication() {
      return this.communication?.find(
        (item) => item?.surveyMessageType === this.messageType
      );
    },
    html() {
      return this.currentCommunication?.message;
    },
  },
};
</script>
