<template>
  <div>
    <div class="flex justify-between">
      <p class="text-xl font-headers mb-1.5 font-bold text-grey-dark-2">
        Communications Details
      </p>
      <div class="flex flex-grow justify-end">
        <Button
          v-if="isReadOnly"
          text="Back"
          type="primary-white"
          size="medium"
          :iconLeft="arrowLeft"
          rotateArrow
          @click.native="goBack"
        />
        <Button
          v-if="!isReadOnly"
          text="Cancel"
          type="secondary"
          size="medium"
          data-testid="next-button"
          class="mr-1"
          @click.native="goBack"
        />
        <Button
          v-if="!isReadOnly"
          text="Next"
          type="primary"
          size="medium"
          @click.native="goNext"
          :iconRight="arrowRight"
        />
      </div>
    </div>
    <ReadOnlyCommunication v-if="isReadOnly" />
    <div
      v-else
      class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4"
      :key="key"
    >
      <div class="mb-4">
        <p class="font-headers font-bold text-mdl text-grey-light mb-2">
          Review Communications Settings Before you Save
        </p>
        <p class="text-mdh text-grey-dark-1 max-w-3/5 mb-3">
          If you need to make changes, you can go back and edit each section of
          the summary below.
        </p>
        <div class="border-t border-grey-light-4" />
      </div>
      <div class="mb-6">
        <h2 class="text-mdl font-bold text-grey-dark-1 mb-6">Communication</h2>
        <SurveyCommunication type="Intro" />
        <SurveyCommunication type="Outro" />
        <SurveyCommunication type="Closed" />
      </div>
      <div>
        <h2 class="text-mdl font-bold text-grey-dark-1 mb-6">
          Email Communications
        </h2>
        <EmailCommunication type="Invitation" />
        <EmailCommunication type="Reminder" />
        <OtherMessages />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import arrowRight from "@/assets/img/icons/arrow-right-white.svg";
import arrowLeft from "@/assets/img/icons/arrow-right.svg";

import SurveyCommunication from "../components/SurveyCommunication";
import EmailCommunication from "../components/EmailCommunication";
import ReadOnlyCommunication from "../components/ReadOnlyCommunication";
import OtherMessages from "../components/OtherMessages";
import Button from "../../../components/Button/Button.vue";

export default {
  name: "ProductCommunication",
  components: {
    SurveyCommunication,
    EmailCommunication,
    ReadOnlyCommunication,
    OtherMessages,
    Button,
  },
  data: () => ({
    arrowRight,
    arrowLeft,
    key: 1,
  }),
  watch: {
    communication() {
      this.key += 1;
    },
  },
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
      communication: (state) => state.products.communication,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
  },
  methods: {
    ...mapActions({
      getSurveyCommunication: "products/getSurveyCommunication",
      getSurveyImage: "products/getSurveyImage",
    }),
    goNext() {
      this.$router.push("dashboard");
    },
    goBack() {
      return this.$router.push(
        `/products/${this.productDetails.productId}/dashboard`
      );
    },
  },
  async mounted() {
    await this.getSurveyCommunication(this.productDetails.id);
    await this.getSurveyImage({id: this.productDetails.id, type: 'Intro'});
    await this.getSurveyImage({id: this.productDetails.id, type: 'Outro'});
    await this.getSurveyImage({id: this.productDetails.id, type: 'Closed'});
  },
};
</script>
