<template>
  <div class="max-w-onboarding pt-2">
    <div class="flex flex-col">
      <div class="flex w-full gap-2 items-center mb-3">
        <InputField
          label="From Address"
          :defaultValue="fromAddress"
          disabled
          class="w-1/2"
        />
        <Select
          label="Audience"
          :options="audienceOptions"
          :value="selectedAudience"
          :preselectedFirst="false"
          :onSelect="(value) => (selectedAudience = value)"
          class="w-1/2"
        />
      </div>
      <div class="flex w-full gap-2 items-end mb-3">
        <InputField
          label="Subject"
          :key="`${type}${key}subject`"
          :defaultValue="subject"
          :onChange="(value) => (subject = value)"
          class="w-1/2"
        />
        <div
        class="space-x-2 items-center flex"
        v-if="canRepeat"
      >
        <InputField
          label="Repeat Every"
          :defaultValue="currentCommunication.nrDays.toString()"
          :propValue="currentCommunication.nrDays.toString()"
          placeholder="0"
          class="w-1/2"
          type="number"
          :onChange="this.setNrDays"
          positiveOnly
        />
        <p class="text-grey-dark-1 text-md mt-2">
          days until assessment close date
        </p>
      </div>
      </div>
      <RTF
        label="Message"
        :key="`${type}${key}rtf`"
        :content="html"
        :haveMinHeight="true"
        :updateHtml="(value) => (html = value)"
      />
      <Button
        text="Update"
        type="primary"
        size="medium"
        class="ml-auto px-5"
        :disabled="disableSave"
        @click.native="save"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import InputField from "../../../../components/InputField/InputField.vue";
import Select from "../../../../components/Select/NewSelect.vue";
import Checkbox from "../../../../components/Checkbox";
import Button from "../../../../components/Button/Button.vue";

export default {
  name: "EmailCommunicationContent",
  props: { type: { default: "Invitation", type: "Invitation" | "Reminder" } },
  components: { InputField, Select, Checkbox, Button },
  data: () => ({
    audienceOptions: [
      { label: "All Participants", id: 0 },
      { label: "Participants with Assessments in Progress", id: 1 },
      { label: "Only Managers", id: 2 },
      { label: "Only Employees", id: 3 },
    ],
    key: 1,
    audience: {},
  }),
  watch: {
    currentCommunication() {
      this.key += 1;
    },
  },
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
      communication: (state) => state.products.communication,
    }),
    messageType() {
      return this.type === "Invitation" ? 1 : 3;
    },
    currentCommunication() {
      return this.communication?.find(
        (item) => item?.surveyMessageType === this.messageType
      );
    },
    canRepeat() {
      return this.messageType !== 1;
    },
    disableSave() {
      return this.html === "<p></p>" || this.subject?.trim() === "";
    },
    fromAddress() {
      return this.currentCommunication?.from;
    },
    html: {
      get() {
        return this.currentCommunication?.message;
      },
      set(value) {
        this.setCommunicationMessage({ type: this.messageType, value });
      },
    },
    subject: {
      get() {
        return this.currentCommunication?.subject;
      },
      set(value) {
        this.setEmailSubject({ type: this.messageType, value });
      },
    },
    selectedAudience: {
      get() {
        return this.audience;
      },
      set(value) {
        this.audience = value;
      },
    },
  },
  methods: {
    ...mapActions({
      setCommunicationMessage: "products/setCommunicationMessage",
      setEmailSubject: "products/setEmailSubject",
      setEmailFrequency: "products/setEmailFrequency",
      updateSurveyCommunication: "products/updateSurveyCommunication",
      getSurveyCommunication: "products/getSurveyCommunication",
    }),
    setNrDays(value) {
        const computedValue = Math.max(0, Number.parseInt(value));
        this.setEmailFrequency({ type: this.messageType, value: computedValue });
    
    },
    async save() {
      const {
        message,
        subject,
        messageFrequency,
        id,
        nrDays,
      } = this.currentCommunication;
      const payload = {
        surveyId: this.productDetails.id,
        messageType: this.messageType,
        audience: this.audience?.id,
        message,
        subject,
        messageFrequency,
        nrDays,
        messageId: id || null,
      };
      await this.updateSurveyCommunication({
        id: this.productDetails.id,
        payload,
      }).then(() => this.getSurveyCommunication(this.productDetails.id));
    },
  },
  mounted() {
    this.audience = this.audienceOptions.find(
      (item) => item.id === this.currentCommunication?.messageAudience
    );
  },
};
</script>
