<template>
  <div class="mx-4">
    <Accordion>
      <AccordionItem class="w-full mb-4">
        <template slot="accordion-trigger">
          <CommunicationItem :title="title[type]" />
        </template>
        <template slot="accordion-content">
          <SurveyCommunicationContent class="w-9/10" :type="type" />
        </template>
      </AccordionItem>
    </Accordion>
  </div>
</template>
<script>
import AccordionItem from "../../../../components/Accordion/AccordionItem.vue";
import Accordion from "../../../../components/Accordion/Accordion.vue";
import CommunicationItem from "./CommunicationItem.vue";
import SurveyCommunicationContent from "./SurveyCommunicationContent.vue";

export default {
  name: "SurveyCommunication",
  components: {
    Accordion,
    AccordionItem,
    CommunicationItem,
    SurveyCommunicationContent,
  },
  props: {
    type: { default: "Intro", type: "Intro" | "Outro" | "Closed" },
  },
  data: () => ({
    title: {
      "Intro": "Intro Screen",
      "Outro": "Outro Screen",
      "Closed": "Closed or Expired Screen",
    },
  }),
};
</script>
