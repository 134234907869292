<template>
  <div class="bg-white shadow rounded px-4 pt-3 pb-4.5 mb-4">
    <h2 class="text-grey-dark-1 text-md-2 font-bold mb-2">Communication</h2>
    <div class="flex flex-col gap-2" :key="key">
      <ReadOnlySurveyCommunicationImage type="Intro" />
      <ReadOnlySurveyCommunication :type="'Intro'" />
      <ReadOnlySurveyCommunicationImage type="Outro" />
      <ReadOnlySurveyCommunication :type="'Outro'" />
      <ReadOnlySurveyCommunicationImage type="Closed" />
      <ReadOnlySurveyCommunication :type="'Closed'" />
      <ReadOnlyEmailCommunication
        type="Invitation"
        :currentCommunication="inviteEmail"
      />
      <ReadOnlyEmailCommunication
        type="Reminder"
        :currentCommunication="reminderEmail"
      />
      <ReadOnlyEmailCommunication
        v-for="email in customEmails"
        :key="email.id"
        type="Custom"
        :currentCommunication="email"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { saveAs } from "file-saver";
import ReadOnlySurveyCommunicationImage from "./ReadOnlySurveyCommunicationImage.vue";
import ReadOnlySurveyCommunication from "./ReadOnlySurveyCommunication.vue";
import ReadOnlyEmailCommunication from "./ReadOnlyEmailCommunication.vue";
import { downloadSurveyImage } from "../../../../services/api/products";

export default {
  name: "ReadOnlyCommunication",
  components: { ReadOnlySurveyCommunication, ReadOnlyEmailCommunication, ReadOnlySurveyCommunicationImage },
  data: () => ({ key: 1 }),
  computed: {
    ...mapState({
      introImage: (state) => state.products.introImage,
      communication: (state) => state.products.communication,
    }),
    fileName() {
      return this.introImage?.fileName || "";
    },
    fileSize() {
      return this.introImage?.fileSize || "";
    },
    inviteEmail() {
      return this.getCommunicationByType(1);
    },
    reminderEmail() {
      return this.getCommunicationByType(3);
    },
    customEmails() {
      return this.communication?.filter(
        (item) => item?.surveyMessageType === 2
      );
    },
  },
  watch: {
    communication() {
      this.key += 1;
    },
  },
  methods: {
    async downloadImage() {
      const rsp = await downloadSurveyImage(this.introImage.id);
      saveAs(rsp.data, this.introImage.fileName);
    },
    getCommunicationByType(type) {
      return this.communication?.find(
        (item) => item?.surveyMessageType === type
      );
    },
  },
};
</script>
