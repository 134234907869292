<template>
  <div class="border-b border-blue-dark-1 w-full flex mb-1">
    <p class="text-grey-dark-1 text-mdl font-bold mb-2">
      {{ title }}
    </p>
    <div class="ml-auto">
      <img
        src="../../../../assets/img/delta-table/caret.svg"
        class="transition-all min-w-24 h-24"
        :class="isActive ? 'transform rotate-180' : null"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "CommunicationItem",
  inject: ["Accordion"],
  props: { title: { default: "", type: String } },
  computed: {
    isActive() {
      return this.Accordion?.active === 0;
    },
  },
};
</script>
