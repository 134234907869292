import { render, staticRenderFns } from "./ReadOnlySurveyCommunication.vue?vue&type=template&id=428dea78&"
import script from "./ReadOnlySurveyCommunication.vue?vue&type=script&lang=js&"
export * from "./ReadOnlySurveyCommunication.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports