<template>
  <div class="max-w-onboarding pt-4 border-b border-grey-light-1 pb-2">
    <p class="font-bold text-mdlh text-grey-dark-1 mb-2">New Email Message</p>
    <div class="flex flex-col">
      <div class="flex w-full gap-2 items-center mb-3">
        <InputField
          label="Message Name"
          placeholder="e.g. Invitation Email"
          :defaultValue="messageName"
          :onChange="(value) => (messageName = value)"
          :key="`${key}name`"
          class="w-1/2"
        />
        <InputField
          label="From Address"
          :defaultValue="message.from"
          :placeholder="message.from"
          disabled
          class="w-1/2"
        />
      </div>
      <div class="flex w-full gap-2 items-end mb-3">
        <Select
          label="Audience"
          :options="audienceOptions"
          :value="selectedAudience"
          :preselectedFirst="false"
          :onSelect="(value) => (selectedAudience = value)"
          class="w-1/2"
        />
        <InputField
          label="Subject"
          placeholder="e.g. Supply Chain Assessment"
          :key="`${key}subject`"
          :defaultValue="subject"
          :onChange="(value) => (subject = value)"
          class="w-1/2"
        />
      </div>
      <div class="space-x-2 items-center flex">
        <InputField
          label="Repeat Every"
          :defaultValue="messageDaysString"
          :propValue="messageDaysString"
          placeholder="0"
          class="w-1/2"
          type="number"
          :onChange="this.setNrDays"
          positiveOnly
        />
        <p class="text-grey-dark-1 text-md mt-2">
          days until assessment close date
        </p>
      </div>
      <RTF
        label="Message"
        :key="`${key}rtf`"
        :content="html"
        :haveMinHeight="true"
        :updateHtml="(value) => (html = value)"
      />
      <div class="flex gap-2 ml-auto">
        <Button
          v-if="!message.id"
          text="Cancel"
          type="secondary"
          size="medium"
          class="px-5"
          @click.native="() => cancel(index)"
        />
        <Button
          v-else
          text="Delete"
          type="danger"
          size="medium"
          class="px-5"
          @click.native="deleteMessage"
        />
        <Button
          :text="saveButtonText"
          type="primary"
          size="medium"
          class="px-5"
          :disabled="disableSave"
          @click.native="() => save(index)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import InputField from "../../../../components/InputField/InputField.vue";
import Select from "../../../../components/Select/NewSelect.vue";
import Checkbox from "../../../../components/Checkbox";
import Button from "../../../../components/Button/Button.vue";
import { deleteCustomMessage } from "../../../../services/api/products";

export default {
  name: "NewMessage",
  props: {
    newMessage: { default: () => {}, type: Object },
    index: { default: null, type: Number },
    cancel: { default: () => {}, type: Function },
    save: { default: () => {}, type: Function },
  },
  components: { InputField, Select, Checkbox, Button },
  data: () => ({
    audienceOptions: [
      { label: "All Participants", id: 0 },
      { label: "Participants with Assessments in Progress", id: 1 },
      { label: "Only Managers", id: 2 },
      { label: "Only Employees", id: 3 },
    ],
    key: 1,
    message: {},
  }),
  watch: {
    message() {
      this.key += 1;
    },
  },
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
    messageDaysString() {
      return this.message?.nrDays?.toString()
    },
    saveButtonText() {
      return this.message.id ? "Update" : "Save";
    },
    disableSave() {
      return (
        this.html === "<p></p>" ||
        this.html === "" ||
        this.subject?.trim() === "" ||
        this.messageName?.trim() === ""
      );
    },
    messageName: {
      get() {
        return this.message.messageName;
      },
      set(value) {
        this.message.messageName = value;
      },
    },
    selectedAudience: {
      get() {
        return this.audienceOptions.filter(
          (item) => item.id === this.message.messageAudience
        )[0];
      },
      set(value) {
        this.message.messageAudience = value.id;
      },
    },
    subject: {
      get() {
        return this.message.subject;
      },
      set(value) {
        this.message.subject = value;
      },
    },
    isRepeating: {
      get() {
        return this.message.messageFrequency === 1;
      },
      set(value) {
        this.message.messageFrequency = value ? 1 : 0;
      },
    },
    html: {
      get() {
        return this.message.message;
      },
      set(value) {
        this.message.message = value;
      },
    },
  },
  methods: {
    ...mapActions({
      getSurveyCommunication: "products/getSurveyCommunication",
      showSuccess: "alerts/showSuccess",
    }),
    setNrDays(value) {
        const computedValue = Math.max(0, Number.parseInt(value));
        this.message.nrDays = computedValue;
        this.message.messageFrequency = +(computedValue > 0);
    },
    deleteMessage() {
      deleteCustomMessage(this.message.id).then(() => {
        this.showSuccess({
          messageHeader: "Message Deleted",
          message:
            "You have successfully deleted the additional email message.",
        });
        this.getSurveyCommunication(this.productDetails.id);
      });
    },
  },
  mounted() {
    this.message = this.newMessage;
  },
};
</script>
